import { useAuth0 } from "@auth0/auth0-react";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationTriangle,
  faEye,
  faListNumeric,
  faPause,
  faPenToSquare,
  faPlus,
  faStethoscope,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import flatMap from "lodash/flatMap";
import sum from "lodash/sum";
import React, { ReactNode, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import "./Apps.css";
import { ListAppsResponseItem } from "./backend";
import AppEnvBadge from "./components/AppEnvBadge";
import AppFrameworkIcon from "./components/AppFrameworkIcon";
import LaunchDemoAlert from "./components/LaunchDemoAlert";
import MainLayout from "./components/MainLayout";
import PageHeader from "./components/PageHeader";
import PlanLimitsExceededAlert from "./components/PlanLimitsExceededAlert";
import SparklineGraph from "./components/SparklineGraph";
import TableCard from "./components/TableCard";
import TableCellDropdown from "./components/TableCellDropdown";
import Tooltip from "./components/Tooltip";
import UnverifiedEmailAlert from "./components/UnverifiedEmailAlert";
import { useGlobal } from "./contexts/GlobalContext";
import AppDeleteModal from "./modals/AppDeleteModal";
import AppDetailsModal from "./modals/AppDetailsModal";
import AppFormModal from "./modals/AppFormModal";

function intersperseTooltipElements(children: React.ReactNode[]): React.ReactNode[] {
  const separator = (
    <span className="mx-1" style={{ opacity: 0.6 }}>
      /
    </span>
  );
  const noWrap = (child: React.ReactNode) => <span className="text-nowrap">{child}</span>;
  return flatMap(children, (child, index, array) =>
    index === array.length - 1 ? noWrap(child) : [noWrap(child), separator],
  ).map((child, index) => {
    return <React.Fragment key={index}>{child}</React.Fragment>;
  });
}

function Apps() {
  const { apps, alertCounts, activeTeam, backendClient, timezone, isTeamAdmin, isDemo } = useGlobal();
  const { user } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const [appInfoModalApp, setAppInfoModalApp] = useState<ListAppsResponseItem>();
  const [appFormModalAppId, setAppFormModalAppId] = useState<number | undefined>(
    searchParams.has("createApp")
      ? 0
      : searchParams.has("editApp")
        ? parseInt(searchParams.get("editApp")!) || undefined
        : undefined,
  );
  const [appDeleteModalApp, setAppDeleteModalApp] = useState<ListAppsResponseItem>();
  const navigate = useNavigate();
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;

  const requestsSparklinesQueryParams = { teamId: activeTeam?.id || 0, timezone };
  const requestsSparklinesQuery = useQuery({
    queryKey: ["appRequestsSparklines", requestsSparklinesQueryParams],
    queryFn: () => backendClient!.traffic.getAppRequestsSparklines(requestsSparklinesQueryParams),
    enabled: !!backendClient && !!activeTeam,
    refetchInterval: 600000,
  });
  const appDataQuery = useQuery({
    queryKey: ["appData", { teamId: activeTeam?.id }],
    queryFn: () => backendClient!.apps.listAppData({ teamId: activeTeam!.id }),
    enabled: !!backendClient && !!activeTeam,
    refetchInterval: 60000,
  });

  useEffect(() => {
    document.title = "Apps - Apitally";
  }, []);

  useEffect(() => {
    if (appFormModalAppId === undefined && searchParams.has("editApp")) {
      setSearchParams((searchParams) => {
        searchParams.delete("editApp");
        return searchParams;
      });
    }
  }, [appFormModalAppId]);

  const requestsColumnInfo = (
    <Tooltip placement="bottom" tooltip="Over the last 24 hours across all environments">
      <FontAwesomeIcon icon={faCircleQuestion} className="ms-2 text-body-tertiary" />
    </Tooltip>
  );

  const appRows = apps?.map((app) => {
    const instances = appDataQuery.data?.[app.id]?.total.instances || 0;
    const consumers = appDataQuery.data?.[app.id]?.total.consumers || 0;
    const activeAlertCount = alertCounts?.[app.id]?.active || 0;

    const envBadges = app.envs.map((env) => {
      const envData = appDataQuery.data?.[app.id]?.[`env:${env.slug}`];
      const envInstances = envData?.instances || 0;
      const envConsumers = envData?.consumers || 0;
      const tooltipElements: ReactNode[] = [];
      if (env.online) {
        tooltipElements.push("Online");
        tooltipElements.push(`${envInstances.toLocaleString()} active instance${envInstances !== 1 ? "s" : ""}`);
      } else {
        tooltipElements.push("Offline");
      }
      if (envConsumers > 0) {
        tooltipElements.push(`${envConsumers.toLocaleString()} consumer${envConsumers !== 1 ? "s" : ""}`);
      }
      if (env?.versions && env.versions.app) {
        tooltipElements.push(`v${env.versions.app}`);
      }
      if (tooltipElements.length === 0) {
        return <AppEnvBadge key={env.id} env={env} className="me-1" />;
      } else {
        const badgeWithTooltip = (
          <Tooltip placement="bottom" tooltip={intersperseTooltipElements(tooltipElements)}>
            <AppEnvBadge key={env.id} env={env} className="me-1" />
          </Tooltip>
        );
        return (
          <Link key={env.id} to={`/traffic/${app.slug}?env=${env.slug}`}>
            {badgeWithTooltip}
          </Link>
        );
      }
    });

    return (
      <tr key={app.id}>
        <td width={54} className="ps-4 align-middle">
          <AppFrameworkIcon framework={app.framework} />
        </td>
        <td>
          <div>
            <Link to={`/traffic/${app.slug}`}>{app.name}</Link>
          </div>
          <div className="small text-muted">
            {appDataQuery.isSuccess && (
              <>
                <span className="d-inline-block text-nowrap">
                  {instances.toLocaleString()} active instance{instances !== 1 ? "s" : ""}
                </span>
                {consumers > -1 && (
                  <>
                    <span className="mx-1" style={{ opacity: 0.5 }}>
                      /
                    </span>
                    <span className="d-inline-block text-nowrap">
                      {consumers.toLocaleString()} consumer{consumers !== 1 ? "s" : ""}
                    </span>
                  </>
                )}
                {!app.suspended && activeAlertCount > 0 && (
                  <>
                    <span className="mx-1" style={{ opacity: 0.5 }}>
                      /
                    </span>
                    <span className="d-inline-block text-nowrap">
                      <Link to={`/alerts?app=${app.id}`} className="text-danger">
                        {activeAlertCount.toLocaleString()} active alert{activeAlertCount !== 1 ? "s" : ""}
                      </Link>
                    </span>
                  </>
                )}
                {app.suspended && (
                  <>
                    <span className="mx-1" style={{ opacity: 0.5 }}>
                      /
                    </span>
                    <Tooltip tooltip="We've temporarily paused data ingestion for this app">
                      <span className="text-danger cursor-default">Paused</span>
                    </Tooltip>
                  </>
                )}
              </>
            )}
            {appDataQuery.isPending && <Placeholder style={{ width: 205 }} />}
          </div>
        </td>
        <td className="d-none d-lg-table-cell">
          <SparklineGraph
            data={requestsSparklinesQuery.data?.[app.id] || [0, 0]}
            max={requestsSparklinesQuery.data?.[app.id] ? undefined : 1}
            linkTo={`/traffic/${app.slug}`}
            tooltip={
              requestsSparklinesQuery.isSuccess
                ? `${sum(requestsSparklinesQuery.data[app.id]).toLocaleString()} requests within the last 24 hours`
                : undefined
            }
          />
        </td>
        <td className="d-none d-md-table-cell">{envBadges}</td>
        <td className="text-end align-middle text-nowrap pe-4">
          {!app.suspended && activeAlertCount > 0 && (
            <Tooltip tooltip={`${activeAlertCount} active alert${activeAlertCount !== 1 ? "s" : ""}`}>
              <Link to={`/alerts?app=${app.id}`} className="text-danger">
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ position: "relative", top: 1 }} />
              </Link>
            </Tooltip>
          )}
          {app.suspended && (
            <Tooltip tooltip="We've temporarily paused data ingestion for this app">
              <span className="text-danger">
                <FontAwesomeIcon icon={faPause} style={{ position: "relative", top: 1 }} />
              </span>
            </Tooltip>
          )}
        </td>
        <TableCellDropdown>
          <Dropdown.Item as="button" onClick={() => setAppInfoModalApp(app)}>
            <FontAwesomeIcon icon={faEye} fixedWidth className="text-secondary" />
            App details
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => navigate(`/apps/${app.slug}/setup`)}>
            <FontAwesomeIcon icon={faListNumeric} fixedWidth className="text-secondary" />
            Setup instructions
          </Dropdown.Item>
          <Dropdown.Divider />
          {isTeamAdminOrDemo && (
            <>
              <Dropdown.Item as="button" onClick={() => setAppFormModalAppId(app.id)}>
                <FontAwesomeIcon icon={faPenToSquare} fixedWidth className="text-secondary" />
                Edit app
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => navigate(`/uptime?configureHealthChecks=${app.id}`)}>
                <FontAwesomeIcon icon={faStethoscope} fixedWidth className="text-secondary" />
                Configure health checks
              </Dropdown.Item>
            </>
          )}
          {isTeamAdminOrDemo && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item
                as="button"
                className="text-danger"
                disabled={!isTeamAdmin}
                onClick={() => setAppDeleteModalApp(app)}
              >
                <FontAwesomeIcon icon={faTrash} fixedWidth />
                Delete app
              </Dropdown.Item>
            </>
          )}
        </TableCellDropdown>
      </tr>
    );
  });

  const pageHeaderButtons = (
    <Tooltip condition={!user?.email_verified} tooltip="Please verify your email address first" placement="bottom">
      <Button
        variant="primary"
        disabled={!user?.email_verified}
        className="float-end"
        onClick={() => setAppFormModalAppId(0)}
      >
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Create app
      </Button>
    </Tooltip>
  );
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={!user?.email_verified} onClick={() => setAppFormModalAppId(0)}>
        <FontAwesomeIcon icon={faPlus} fixedWidth />
        Create app
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <div className="Apps">
        <AppDetailsModal
          app={appInfoModalApp}
          setApp={setAppInfoModalApp}
          onEditClick={() => {
            const appId = appInfoModalApp?.id;
            setAppInfoModalApp(undefined);
            setAppFormModalAppId(appId);
          }}
        />
        {isTeamAdminOrDemo && (
          <>
            <AppFormModal appId={appFormModalAppId} setAppId={setAppFormModalAppId} />
            <AppDeleteModal app={appDeleteModalApp} setApp={setAppDeleteModalApp} />
          </>
        )}
        <PageHeader
          buttons={isTeamAdminOrDemo ? pageHeaderButtons : undefined}
          dropdownMenu={isTeamAdminOrDemo ? pageHeaderDropdown : undefined}
        >
          Apps
        </PageHeader>
        {user && !user.email_verified && <UnverifiedEmailAlert />}
        <PlanLimitsExceededAlert />
        {(!apps || apps.length > 0) && (
          <TableCard>
            <thead>
              <tr>
                <th></th>
                <th>App name</th>
                <th className="d-none d-lg-table-cell">Requests{requestsColumnInfo}</th>
                <th className="d-none d-md-table-cell">Environments</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {appRows}
              {!apps && (
                <Placeholder as="tr" animation="glow">
                  <td width={54}></td>
                  <td>
                    <div className="text-primary">
                      <Placeholder xs={4} />
                    </div>
                    <div className="small text-body-secondary">
                      <Placeholder style={{ width: 205 }} />
                    </div>
                  </td>
                  <td className="d-none d-lg-table-cell"></td>
                  <td className="d-none d-md-table-cell"></td>
                  <td></td>
                  <td width={40}></td>
                </Placeholder>
              )}
            </tbody>
          </TableCard>
        )}
        {apps && apps.length === 0 && (
          <>
            <Card className="bt">
              <Card.Body className="text-center py-6">
                <h2>Hi there, welcome to Apitally! 👋</h2>
                {isTeamAdmin ? (
                  <>
                    <div>Let's get your applications set up. Click the button below to get started.</div>
                    <div className="mt-6">
                      <Tooltip
                        condition={!user?.email_verified}
                        tooltip="Please verify your email address first"
                        placement="bottom"
                      >
                        <Button
                          variant="primary"
                          onClick={() => setAppFormModalAppId(0)}
                          disabled={!user?.email_verified}
                        >
                          Create app
                        </Button>
                      </Tooltip>
                    </div>
                    <div className="mt-6 small text-muted">
                      Or, check out our{" "}
                      <a href="https://docs.apitally.io" target="_blank">
                        documentation
                      </a>{" "}
                      to learn more before you dive in.
                    </div>
                  </>
                ) : (
                  <div>
                    Your team has no applications yet. Once created by your team's administrators you will see
                    applications here.
                  </div>
                )}
              </Card.Body>
            </Card>
          </>
        )}
        {!isDemo && <LaunchDemoAlert />}
      </div>
    </MainLayout>
  );
}

export default Apps;

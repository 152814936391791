import {
  faAngleRight,
  faBug,
  faChartArea,
  faChartColumn,
  faChartSimple,
  faCheck,
  faEye,
  faEyeSlash,
  faFilter,
  faRotateLeft,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import "./Errors.css";
import { ErrorsTableItem } from "./backend";
import ErrorRatesLineChart from "./charts/ErrorRatesLineChart";
import ErrorsBarChart from "./charts/ErrorsBarChart";
import AppDropdown from "./components/AppDropdown";
import AppEnvDropdown, { AppEnvDropdownItems } from "./components/AppEnvDropdown";
import CustomIcon from "./components/CustomIcon";
import FilterBadges from "./components/FilterBadges";
import MainLayout from "./components/MainLayout";
import MethodPath from "./components/MethodPath";
import MetricNavItem from "./components/MetricNavItem";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import PeriodDropdown, { PeriodDropdownItems } from "./components/PeriodDropdown";
import PlanLimitsExceededAlert from "./components/PlanLimitsExceededAlert";
import RefreshButton from "./components/RefreshButton";
import StatusCode from "./components/StatusCode";
import TableCard, { ColumnHeader, SortBy } from "./components/TableCard";
import TableCardSearchHeader from "./components/TableCardSearchHeader";
import TableCellDropdown from "./components/TableCellDropdown";
import TableCellWithBar from "./components/TableCellWithBar";
import TeaserCard from "./components/TeaserCard";
import { useConfirmation } from "./contexts/ConfirmationContext";
import FilterContextProvider, { useFilters } from "./contexts/FilterContext";
import { useGlobal } from "./contexts/GlobalContext";
import CustomPeriodModal from "./modals/CustomPeriodModal";
import DashboardFilterModal from "./modals/DashboardFilterModal";
import EndpointErrorModal from "./modals/EndpointErrorModal";
import { EndpointError } from "./types/Endpoint";
import { isCustomPeriod } from "./utils/period";

type ErrorsTableProps = {
  filterErrorType?: "server" | "client";
};

function ErrorsTable({ filterErrorType }: ErrorsTableProps) {
  const { backendClient, isDemo, isTeamAdmin, timezone } = useGlobal();
  const { app, env, period, consumerId, consumerGroupId, endpointGroupId } = useFilters();
  const { maybeConfirm } = useConfirmation();
  const [sortedData, setSortedData] = useState<ErrorsTableItem[]>();
  const [showExpected, setShowExpected] = useState(false);
  const [modalEndpointError, setModalEndpointError] = useState<EndpointError>();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState<ErrorsTableItem[]>();
  const [sortBy, setSortBy] = useState<SortBy>({ column: "requests", direction: "desc" });
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointGroupId,
    period,
    timezone,
  };
  const query = useQuery({
    queryKey: ["errorsTable", queryParams],
    queryFn: () => backendClient!.errors.getErrorsTable(queryParams),
    enabled: !!backendClient && !!app,
  });

  useEffect(() => {
    let data = query.data;
    if (data) {
      if (filterErrorType) {
        data = data.filter((item) => {
          return filterErrorType === "client"
            ? item.status_code >= 400 && item.status_code < 500
            : item.status_code >= 500;
        });
      }
      data = sortData(data, sortBy);
    }
    setSortedData(data);
  }, [query.data, filterErrorType, sortBy]);

  useEffect(() => {
    if (search) {
      const searchTerms = search.toLowerCase().trim().split(/\s+/);
      setSearchResult(
        sortedData?.filter((item) =>
          searchTerms.every(
            (term) =>
              item.status_code.toString() == term ||
              item.status_text.toLowerCase().includes(term) ||
              item.path.toLowerCase().includes(term),
          ),
        ),
      );
    } else {
      setSearchResult(sortedData);
    }
  }, [search, sortedData]);

  useEffect(() => {
    const method = searchParams.get("method");
    const path = searchParams.get("path");
    const status_code = searchParams.get("status_code");
    if (method && path && status_code && query.isSuccess) {
      const item = query.data.find(
        (item) => item.method === method && item.path === path && item.status_code === parseInt(status_code),
      );
      if (item) {
        setModalEndpointError({
          id: null,
          method: item.method,
          path: item.path,
          status_code: item.status_code,
          status_text: item.status_text,
          expected: item.expected,
        });
        setSearchParams(
          (searchParams) => {
            searchParams.delete("method");
            searchParams.delete("path");
            searchParams.delete("status_code");
            return searchParams;
          },
          { replace: true },
        );
      }
    }
  }, [query.isSuccess, searchParams]);

  const sortData = (data: ErrorsTableItem[], sortBy: SortBy) => {
    const sort = (a: ErrorsTableItem, b: ErrorsTableItem) => {
      const sign = sortBy.direction === "asc" ? 1 : -1;

      if (a.expected && !b.expected) {
        return 1;
      } else if (!a.expected && b.expected) {
        return -1;
      }

      if (sortBy.column === "status_code") {
        return (a.status_code - b.status_code) * sign;
      } else if (sortBy.column === "endpoint") {
        return a.path.localeCompare(b.path) * sign;
      } else if (sortBy.column === "requests") {
        return (a.request_count - b.request_count) * sign;
      } else if (sortBy.column === "affected_consumers") {
        return (a.affected_consumers - b.affected_consumers) * sign;
      } else {
        return 0;
      }
    };
    return data?.slice().sort(sort);
  };

  const toggleExpected = async (endpointError: ErrorsTableItem, expected: boolean) => {
    if (backendClient && app) {
      await backendClient.endpoints.updateEndpointErrorConfig({
        appId: app.id,
        requestBody: {
          method: endpointError.method,
          path: endpointError.path,
          status_code: endpointError.status_code,
          expected,
        },
      });
      await queryClient.refetchQueries({ type: "active" });
    }
  };

  const unexpectedErrors = query.data?.filter((item) => !item.expected);
  const maxRequestCount = unexpectedErrors?.reduce((max, item) => Math.max(max, item.request_count), 0);
  const maxAffectedConsumers = unexpectedErrors?.reduce((max, item) => Math.max(max, item.affected_consumers), 0);
  const expectedErrorItems = searchResult?.filter((item) => item.expected);
  const tableFooter =
    expectedErrorItems && expectedErrorItems.length > 0 ? (
      <div className="small">
        {!showExpected ? (
          <Button variant="link" onClick={() => setShowExpected(true)}>
            <FontAwesomeIcon icon={faEye} className="me-icon" />
            Show {expectedErrorItems.length} expected error
            {expectedErrorItems.length > 1 ? "s" : ""}
          </Button>
        ) : (
          <Button variant="link" onClick={() => setShowExpected(false)}>
            <FontAwesomeIcon icon={faEyeSlash} className="me-icon" />
            Hide {expectedErrorItems.length} expected error
            {expectedErrorItems.length > 1 ? "s" : ""}
          </Button>
        )}
      </div>
    ) : undefined;
  const searchHeader =
    query.isSuccess && query.data.length > 10 ? (
      <TableCardSearchHeader search={search} setSearch={setSearch} placeholder="Search errors" />
    ) : undefined;

  if (!app) {
    return;
  }

  return (
    <div className="ErrorsTable">
      <EndpointErrorModal endpointError={modalEndpointError} setEndpointError={setModalEndpointError} />
      <TableCard
        header={searchHeader}
        footer={tableFooter}
        hover={query.isSuccess && query.data.length > 0 && searchResult && searchResult.length > 0}
        responsive
        className="align-middle"
      >
        <thead>
          <tr>
            <th style={{ minWidth: "40px" }}></th>
            <ColumnHeader name="status_code" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
              Response status
            </ColumnHeader>
            <ColumnHeader name="endpoint" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
              Endpoint
            </ColumnHeader>
            <ColumnHeader name="requests" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="desc">
              Occurrences
            </ColumnHeader>
            <ColumnHeader name="affected_consumers" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="desc">
              Consumers
            </ColumnHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {searchResult
            ?.filter((item) => !item.expected || showExpected)
            .map((item) => (
              <tr
                key={`${item.status_code} ${item.method} ${item.path}`}
                className="cursor-pointer"
                onClick={(e) => {
                  const cell = (e.target as HTMLElement).closest("td");
                  if (!cell?.classList.contains("TableCellDropdown")) {
                    setModalEndpointError({
                      id: null,
                      method: item.method,
                      path: item.path,
                      status_code: item.status_code,
                      status_text: item.status_text,
                      expected: item.expected,
                    });
                  }
                }}
              >
                <td width={40}>
                  <FontAwesomeIcon icon={item.expected ? faCheck : faAngleRight} className="ms-2 text-very-muted" />
                </td>
                <td className="text-nowrap">
                  <StatusCode code={item.status_code} text={item.status_text} />
                </td>
                <td className="endpoint-column">
                  <MethodPath method={item.method} path={item.path} />
                </td>
                <TableCellWithBar
                  value={item.request_count}
                  maxValue={maxRequestCount}
                  showBar={!item.expected && sortBy.column === "requests"}
                >
                  {item.request_count.toLocaleString()}
                </TableCellWithBar>
                <TableCellWithBar
                  value={item.affected_consumers}
                  maxValue={maxAffectedConsumers}
                  showBar={!item.expected && sortBy.column === "affected_consumers"}
                >
                  {item.affected_consumers > 0 ? item.affected_consumers.toLocaleString() : "-"}
                </TableCellWithBar>
                <TableCellDropdown>
                  <Dropdown.Item
                    as="button"
                    onClick={() =>
                      setModalEndpointError({
                        id: null,
                        method: item.method,
                        path: item.path,
                        status_code: item.status_code,
                        status_text: item.status_text,
                        expected: item.expected,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faChartSimple} fixedWidth className="text-secondary" />
                    Error insights
                  </Dropdown.Item>
                  {isTeamAdminOrDemo && (
                    <>
                      <Dropdown.Divider />
                      {!item.expected && (
                        <Dropdown.Item
                          as="button"
                          disabled={!isTeamAdmin || item.status_code >= 500}
                          onClick={() =>
                            maybeConfirm({
                              title: "Mark error as expected",
                              body: (
                                <p>
                                  This will exclude the {item.status_code} status code from error rate calculations for
                                  this endpoint, and requests with the {item.status_code} response status code will be
                                  considered successful.
                                </p>
                              ),
                              onConfirm: () => toggleExpected(item, true),
                              dontAskAgainKey: "markErrorAsExpected",
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faCheck} fixedWidth className="text-secondary" />
                          Mark as expected
                        </Dropdown.Item>
                      )}
                      {item.expected && (
                        <Dropdown.Item as="button" disabled={!isTeamAdmin} onClick={() => toggleExpected(item, false)}>
                          <FontAwesomeIcon icon={faRotateLeft} fixedWidth className="text-secondary" />
                          Mark as unexpected
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                </TableCellDropdown>
              </tr>
            ))}
          {query.isSuccess && query.data.length > 0 && search && searchResult && searchResult.length === 0 && (
            <tr>
              <td width={40}></td>
              <td colSpan={4} className="text-center py-6">
                Your search didn't match any errors.
              </td>
              <td width={40}></td>
            </tr>
          )}
          {query.isSuccess &&
            (query.data.length == 0 || query.data.every((item) => item.expected && !showExpected)) && (
              <tr>
                <td width={40}></td>
                <td colSpan={4} className="text-center py-6">
                  No failed requests in the selected period.
                </td>
                <td width={40}></td>
              </tr>
            )}
          {query.isPending &&
            [...Array(3)].map((e, i) => (
              <Placeholder key={i} as="tr" animation="glow">
                <td width={40}>
                  <FontAwesomeIcon icon={faAngleRight} className="ms-2 text-very-muted" />
                </td>
                <td style={{ width: "30%" }}>
                  <Placeholder
                    xs={3}
                    size="lg"
                    bg="danger"
                    style={{ width: "2.5rem", borderRadius: "0.375rem", marginRight: "0.75rem" }}
                  />
                  <Placeholder xs={5} />
                </td>
                <td style={{ width: "40%" }}>
                  <Placeholder
                    xs={3}
                    size="lg"
                    bg="primary"
                    style={{ width: "3.5rem", borderRadius: "0.375rem", marginRight: "0.75rem" }}
                  />
                  <Placeholder xs={8} />
                </td>
                <td>
                  <Placeholder xs={6} />
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td></td>
              </Placeholder>
            ))}
        </tbody>
      </TableCard>
    </div>
  );
}

function ErrorsTeaser() {
  const { launchDemo } = useGlobal();

  const pageHeaderButtons = (
    <Button variant="outline-light" className="float-end" onClick={() => launchDemo()}>
      Explore with demo data
    </Button>
  );
  return (
    <MainLayout>
      <div className="ErrorsTeaser">
        <PageHeader buttons={pageHeaderButtons}>
          <>Errors</>
          <span className="text-muted">No apps set up yet</span>
        </PageHeader>
        <TeaserCard icon={faBug}>
          <h2>Keep track of client & server errors</h2>
          <p className="mt-4">
            The errors dashboard provides an overview of failed requests made by your API consumers, whether expected or
            not. You can dive into details for each error, understand the frequency of occurrence and which consumers
            are affected.
          </p>
          <ul className="my-4">
            <li>
              <b>Client errors:</b> Track the occurrence of 4xx responses or mark them as expected
            </li>
            <li>
              <b>Server errors:</b> Keep an eye on server errors, with details about exceptions, tracebacks and Sentry
              integration
            </li>
            <li>
              <b>Error rate:</b> Analyze the percentage of failed requests and how it evolves over time
            </li>
          </ul>
        </TeaserCard>
      </div>
    </MainLayout>
  );
}

function Errors() {
  const { apps, activeApp, backendClient, timezone } = useGlobal();
  const { app, appSlug, env, setEnv, period, setPeriod, consumerId, consumerGroupId, endpointGroupId } = useFilters();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showCustomPeriodModal, setShowCustomPeriodModal] = useState(false);
  const [metricTab, setMetricTab] = useState("total-errors");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isLoading = useIsFetching() > 0;

  const metricsQueryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointGroupId,
    period,
    timezone,
  };
  const metricsQuery = useQuery({
    queryKey: ["errorsMetrics", metricsQueryParams],
    queryFn: () => backendClient!.errors.getErrorsMetrics(metricsQueryParams),
    enabled: !!backendClient && !!app,
  });

  useEffect(() => {
    if (app) {
      document.title = `Errors - ${app.name} - Apitally`;
    } else {
      document.title = `Errors - Apitally`;
    }
  }, [app]);

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  } else if (apps.length === 0) {
    return <ErrorsTeaser />;
  } else if (!appSlug) {
    if (activeApp) {
      return (
        <MainLayout>
          <Navigate to={`/errors/${activeApp.slug}`} />
        </MainLayout>
      );
    } else {
      return (
        <MainLayout>
          <Navigate to={`/errors/${apps[0].slug}`} />
        </MainLayout>
      );
    }
  } else if (!app) {
    return (
      <MainLayout>
        <Navigate to="/apps" />
      </MainLayout>
    );
  }

  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };
  const filterCount = (consumerId ? 1 : 0) + (consumerGroupId ? 1 : 0) + (endpointGroupId ? 1 : 0);
  const filterCountBadge =
    filterCount > 0 ? (
      <Badge pill bg="secondary" className="ms-2">
        {filterCount}
      </Badge>
    ) : undefined;
  const pageHeaderButtons = [
    <PeriodDropdown
      key="period-dropdown"
      align="end"
      period={period}
      setPeriod={setPeriod}
      setShowCustomPeriodModal={setShowCustomPeriodModal}
    />,
    <AppEnvDropdown key="app-env-dropdown" appEnv={env} setAppEnv={setEnv} />,
    <Button key="filter-button" variant="outline-light" onClick={() => setShowFilterModal(true)}>
      <CustomIcon src="/icons/filter-regular.svg" className="me-icon text-secondary" />
      Filter
      {filterCountBadge}
    </Button>,
    <RefreshButton key="refresh-button" onClick={refresh} loading={isLoading} />,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={isLoading} onClick={refresh}>
        <FontAwesomeIcon icon={faRotateRight} fixedWidth />
        Refresh
      </Dropdown.Item>
      <Dropdown.Item as="button" onClick={() => setShowFilterModal(true)}>
        <FontAwesomeIcon icon={faFilter} fixedWidth />
        Filter
        {filterCountBadge}
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Select period</Dropdown.Header>
      <PeriodDropdownItems period={period} setPeriod={setPeriod} />
      <Dropdown.Item active={isCustomPeriod(period)} onClick={() => setShowCustomPeriodModal(true)}>
        Custom
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Filter by environment</Dropdown.Header>
      <AppEnvDropdownItems appEnv={env} setAppEnv={setEnv} />
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <div className="Errors">
        <DashboardFilterModal show={showFilterModal} setShow={setShowFilterModal} />
        <CustomPeriodModal show={showCustomPeriodModal} setShow={setShowCustomPeriodModal} />
        <PageHeader breakpoint="lg" buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          <>Errors</>
          <AppDropdown onSelect={(key) => key !== null && navigate(`/errors/${key}`)} />
        </PageHeader>
        <PlanLimitsExceededAlert />
        <FilterBadges />
        <Card className="my-4 mb-lg-6 bt">
          <Card.Body className="p-0">
            <Tab.Container
              activeKey={metricTab}
              onSelect={(key) => {
                if (key) {
                  setMetricTab(key);
                }
              }}
            >
              <Nav>
                <Container fluid>
                  <Row>
                    <MetricNavItem
                      eventKey="total-errors"
                      label="Total errors"
                      value={metricsQuery.data?.total_error_count.toLocaleString()}
                      icon={faChartColumn}
                      sm={6}
                      lg={3}
                    />
                    <MetricNavItem
                      eventKey="client-errors"
                      label="Client errors"
                      value={metricsQuery.data?.client_error_count.toLocaleString()}
                      description="4xx responses"
                      icon={faChartColumn}
                      sm={6}
                      lg={3}
                    />
                    <MetricNavItem
                      eventKey="server-errors"
                      label="Server errors"
                      value={metricsQuery.data?.server_error_count.toLocaleString()}
                      description="5xx responses"
                      icon={faChartColumn}
                      sm={6}
                      lg={3}
                    />
                    <MetricNavItem
                      eventKey="error-rate"
                      label="Error rate"
                      value={
                        (metricsQuery.data?.total_request_count ?? 0 > 0)
                          ? metricsQuery.data?.error_rate
                              .toLocaleString(undefined, { style: "percent", minimumFractionDigits: 1 })
                              .replace("%", " %")
                          : "-"
                      }
                      description="Percentage of failed requests (4xx and 5xx responses)"
                      icon={faChartArea}
                      sm={6}
                      lg={3}
                    />
                  </Row>
                  <Row>
                    <Col sm={12} className="py-2 text-center chart">
                      <Tab.Content>
                        <Tab.Pane eventKey="total-errors">
                          <ErrorsBarChart />
                        </Tab.Pane>
                        <Tab.Pane eventKey="client-errors">
                          <ErrorsBarChart filterErrorType="client" />
                        </Tab.Pane>
                        <Tab.Pane eventKey="server-errors">
                          <ErrorsBarChart filterErrorType="server" />
                        </Tab.Pane>
                        <Tab.Pane eventKey="error-rate">
                          <ErrorRatesLineChart />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Container>
              </Nav>
            </Tab.Container>
          </Card.Body>
        </Card>
        <ErrorsTable
          filterErrorType={
            metricTab === "client-errors" ? "client" : metricTab === "server-errors" ? "server" : undefined
          }
        />
      </div>
    </MainLayout>
  );
}

function ErrorsWithFilterContext() {
  return (
    <FilterContextProvider>
      <Errors />
    </FilterContextProvider>
  );
}

export default ErrorsWithFilterContext;

import { useQuery } from "@tanstack/react-query";
import {
  BarController,
  BarElement,
  Chart as ChartJS,
  LinearScale,
  Tick,
  TimeSeriesScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import "chartjs-adapter-luxon";
import { memo } from "react";
import { Bar } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import { rgba } from "polished";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";
import { formatDataSize } from "../utils/numbers";

ChartJS.register(LinearScale, TimeSeriesScale, BarController, BarElement, Title, Tooltip);

type DataTransferredBarChartProps = {
  endpoint?: Endpoint;
  displayTitle?: boolean;
};

function DataTransferredBarChart({ endpoint, displayTitle = true }: DataTransferredBarChartProps) {
  const { backendClient, timezone } = useGlobal();
  const { app, period, setPeriod, resetPeriod, env, consumerId, consumerGroupId, endpointGroupId } = useFilters();

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointGroupId,
    method: endpoint?.method,
    path: endpoint?.path,
    period,
    timezone,
  };
  const query = useQuery({
    queryKey: ["dataTransferredChart", queryParams],
    queryFn: () => backendClient!.traffic.getDataTransferredChart(queryParams),
    enabled: !!backendClient && !!app,
  });

  if (query.isSuccess) {
    const primaryColor = getColor("primary");
    const secondaryColor = getColor("secondary");
    const chartOptions = merge(
      getChartOptions({
        labels: query.data.time_windows,
        title: displayTitle ? "Data transferred" : undefined,
        setPeriod,
        resetPeriod,
      }),
      {
        scales: {
          x: { stacked: true },
          y: {
            stacked: true,
            ticks: {
              callback: function (value: string | number, index: number, ticks: Tick[]) {
                return formatDataSize(Number(value), ticks.at(-1)?.value);
              },
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context: TooltipItem<"bar">) {
                const value = formatDataSize(context.parsed.y);
                return ` ${context.dataset.label}: ${value}`;
              },
            },
          },
        },
      },
    );
    const chartData = {
      labels: query.data.time_windows,
      datasets: [
        {
          label: "Outgoing",
          data: query.data.response_size_sums,
          backgroundColor: primaryColor,
          hoverBackgroundColor: rgba(primaryColor, 0.8),
        },
        {
          label: "Incoming",
          data: query.data.request_size_sums,
          backgroundColor: secondaryColor,
          hoverBackgroundColor: rgba(secondaryColor, 0.8),
        },
      ],
    };
    return (
      <div style={{ position: "relative", height: "220px", width: "100%" }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    );
  } else {
    return <></>;
  }
}

export default memo(DataTransferredBarChart);

import { parseChangelogItems } from "./utils";

const changelog = [
  {
    date: "2024-09-27",
    title: "Changelog",
    description: `
      You're looking at the new changelog in Apitally! From now on we'll keep you updated about new features and improvements here.
    `,
  },
  {
    date: "2024-09-26",
    title: "Persistent filters",
    description: `
      Filters applied on a dashboard are now persisted when you navigate to a different page.
    `,
  },
  {
    date: "2024-09-25",
    title: "Click & drag on charts to select period",
    description: `
      You can now click and drag on any timeseries chart in Apitally to filter the dashboard to a custom period. Try it out!
    `,
    image: "period-selection.webp",
  },
  {
    date: "2024-09-19",
    title: "Delete account",
    description: `
      Users can now delete their account from the settings page.
    `,
    image: "delete-account.webp",
  },
  {
    date: "2024-09-12",
    title: "Updated consumers dashboard",
    description: `
      The consumers dashboard now shows metrics at the top and highlights new consumers.

      The table is now filtered to only show consumers that have made requests in the selected period.
    `,
    image: "new-consumers.webp",
  },
  {
    date: "2024-08-28",
    title: "Requests per minute",
    description: `
      The traffic dashboard now includes a metric and chart for number of requests per minute.
    `,
    image: "requests-per-minute-2.webp",
  },
  {
    date: "2024-08-27",
    title: "Metrics on errors dashboard",
    description: `
      The errors dashboard now shows error count and error rate metrics at the top. Clicking on the metrics updates the chart and table below.
    `,
    image: "error-metrics-4.webp",
  },
  {
    date: "2024-08-26",
    title: "New performance dashboard",
    description: `
      The new performance dashboard is all about Apdex scores and response time percentiles. Check it out!

      You can now also configure Apdex thresholds for your apps and individual endpoints.
    `,
    image: "performance-dashboard-3.webp",
  },
  {
    date: "2024-08-07",
    title: "New endpoint groups page",
    description: `
      Got many endpoints? The new endpoint groups page help you understand usage of different parts of your API.

      Endpoints are grouped automatically based on common path prefixes.
    `,
    image: "endpoint-groups-2.webp",
  },
  {
    date: "2024-07-26",
    title: "Redesign",
    description: `
      The Apitally dashboard now has a fresh new look with a sidebar for easier navigation.
    `,
  },
];

export default parseChangelogItems(changelog);

import { useQuery } from "@tanstack/react-query";
import {
  BarController,
  BarElement,
  Chart as ChartJS,
  LinearScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import "chartjs-adapter-luxon";
import { getReasonPhrase } from "http-status-codes";
import { memo } from "react";
import { Bar } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint, EndpointError } from "../types/Endpoint";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";

ChartJS.register(LinearScale, TimeSeriesScale, BarController, BarElement, Title, Tooltip);

type ErrorsBarChartProps = {
  endpoint?: Endpoint;
  endpointError?: EndpointError;
  displayTitle?: boolean;
  filterErrorType?: "client" | "server";
};

function ErrorsBarChart({ endpoint, endpointError, displayTitle = true, filterErrorType }: ErrorsBarChartProps) {
  const { backendClient, timezone } = useGlobal();
  const { app, period, setPeriod, resetPeriod, env, consumerId, consumerGroupId, endpointGroupId } = useFilters();

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointGroupId,
    method: endpointError?.method || endpoint?.method,
    path: endpointError?.path || endpoint?.path,
    statusCode: endpointError?.status_code,
    period,
    timezone,
  };
  const query = useQuery({
    queryKey: ["errorsChart", queryParams],
    queryFn: () => backendClient!.errors.getErrorsChart(queryParams),
    enabled: !!backendClient && !!app,
  });

  if (query.isSuccess && query.data.length > 0) {
    let title = "Number of client and server errors";
    if (filterErrorType === "client") {
      title = "Number of client errors";
    } else if (filterErrorType === "server") {
      title = "Number of server errors";
    }
    const dangerColor = getColor("danger");
    const chartOptions = merge(
      getChartOptions({
        labels: query.data[0].time_windows,
        title: displayTitle ? title : undefined,
        setPeriod,
        resetPeriod,
      }),
      {
        scales: {
          x: { stacked: true },
          y: { stacked: true, ticks: { precision: 0 } },
        },
        plugins: {
          tooltip: {
            filter: (context: TooltipItem<"bar">) => {
              return context.parsed.y > 0;
            },
            itemSort: (context: TooltipItem<"bar">) => {
              return context.dataset.label === "Server error" ? 1 : -1;
            },
            callbacks: {
              label: (context: TooltipItem<"bar">) => {
                const statusCodeCounts = (context.dataset as any).statusCodeCounts[context.dataIndex] as number[][];
                return statusCodeCounts.map(([statusCode, count]) => {
                  return ` ${statusCode} ${getReasonPhrase(statusCode)}: ${count.toLocaleString()}`;
                });
              },
            },
          },
        },
      },
    );
    const chartData = {
      labels: query.data[0].time_windows,
      datasets: query.data
        .filter(
          (dataset) =>
            !filterErrorType ||
            (filterErrorType === "client" && dataset.error_type === "Client error") ||
            (filterErrorType === "server" && dataset.error_type === "Server error"),
        )
        .map((dataset) => ({
          label: dataset.error_type,
          data: dataset.request_counts,
          statusCodeCounts: dataset.status_code_counts,
          backgroundColor: dataset.error_type === "Server error" ? dangerColor : "#dea6a6",
          hoverBackgroundColor: dataset.error_type === "Server error" ? dangerColor : "#dea6a6",
        })),
    };
    return (
      <div style={{ position: "relative", height: "220px", width: "100%" }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    );
  } else {
    return <></>;
  }
}

export default memo(ErrorsBarChart);

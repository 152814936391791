import { useQuery } from "@tanstack/react-query";
import { BarController, BarElement, Chart as ChartJS, LinearScale, TimeSeriesScale, Title, Tooltip } from "chart.js";
import "chartjs-adapter-luxon";
import { rgba } from "polished";
import { memo } from "react";
import { Bar } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";

ChartJS.register(LinearScale, TimeSeriesScale, BarController, BarElement, Title, Tooltip);

type ConsumersBarChartProps = {
  onlyNew?: boolean;
};

function ConsumersBarChart({ onlyNew }: ConsumersBarChartProps) {
  const { backendClient, timezone } = useGlobal();
  const { app, env, period, setPeriod, resetPeriod } = useFilters();

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    period,
    timezone,
  };
  const query = useQuery({
    queryKey: ["consumersChart", queryParams],
    queryFn: () => backendClient!.consumers.getConsumersChart(queryParams),
    enabled: !!backendClient && !!app,
  });

  if (query.isSuccess) {
    const primaryColor = getColor("primary");
    const secondaryColor = getColor("secondary");
    const colorMap = new Map([
      ["New", primaryColor],
      ["Existing", secondaryColor],
    ]);
    const chartOptions = merge(
      getChartOptions({
        labels: query.data[0].time_windows,
        title: !onlyNew ? "Number of unique consumers" : "Number of new consumers",
        setPeriod,
        resetPeriod,
      }),
      {
        scales: { x: { stacked: true }, y: { stacked: true, ticks: { precision: 0 } } },
      },
    );
    const chartData = {
      labels: query.data[0].time_windows,
      datasets: query.data
        .filter((dataset) => !onlyNew || dataset.consumer_status === "New")
        .map((dataset) => {
          const color = colorMap.get(dataset.consumer_status) || secondaryColor;
          return {
            label: dataset.consumer_status,
            data: dataset.consumer_counts,
            backgroundColor: color,
            hoverBackgroundColor: rgba(color, 0.8),
          };
        }),
    };
    return (
      <div style={{ position: "relative", height: "220px", width: "100%" }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    );
  } else {
    return <></>;
  }
}

export default memo(ConsumersBarChart);
